var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "view-content" },
    [
      _vm.conversation_message_uuid == ""
        ? _c("Message", {
            attrs: {
              message_uuid: _vm.current_message_uuid,
              functionbox_uuid: _vm.functionbox_uuid,
              user_uuid: _vm.user_uuid,
            },
            on: { showConversation: _vm.showConversation },
          })
        : _vm._e(),
      _vm.conversation_message_uuid != ""
        ? _c("Conversation", {
            attrs: {
              message_uuid: _vm.conversation_message_uuid,
              inbox: true,
              functionbox_uuid: _vm.functionbox_uuid,
              "show-close-btn": "",
              user_uuid: _vm.user_uuid,
            },
            on: {
              gotoMessage: _vm.gotoMessage,
              closeConversation: _vm.closeConversation,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }