<template>
  <section class="view-content">
    
    <Message 
      v-if="conversation_message_uuid == ''"
      :message_uuid="current_message_uuid"
      :functionbox_uuid="functionbox_uuid"
      @showConversation="showConversation"      
      :user_uuid="user_uuid"></Message>

    <Conversation v-if="conversation_message_uuid != ''"
    :message_uuid="conversation_message_uuid"
    :inbox="true"
    :functionbox_uuid="functionbox_uuid"
    @gotoMessage="gotoMessage"
    @closeConversation="closeConversation"
    show-close-btn
    :user_uuid="user_uuid"></Conversation>

  </section>    
</template>
<script>
import Conversation from "@/components/Conversation/Conversation";
import Message from "@/components/View/Message";
export default {
  props: ["message_uuid","functionbox_uuid", "user_uuid"],
  components: {
    Message,
    Conversation
  },
  data() {
    return {
      current_message_uuid: this.message_uuid,
      conversation_message_uuid: ''
    };
  },
  methods: {
    closeConversation(){
      this.conversation_message_uuid = "";
    },
    gotoMessage(message_uuid)
    {
      this.current_message_uuid = message_uuid;
      this.conversation_message_uuid = "";
    },
    showConversation(message_uuid)
    {
      this.conversation_message_uuid = message_uuid;
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function() {
  },
};
</script>
<style></style>
